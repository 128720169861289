import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  getUserDetailWithCardNumber({ dispatch, commit }, data) {
    let query;
    if (data.cardNumber) {
      console.log("cardNumber : ", data);
      query = `Business/GetUserDetailByBusiness?CardNumber=${data.cardNumber}&BranchId=${data.branchId}`
    } else if (data.phoneNumber) {
      console.log("phoneNumber : ", data);
      query = `Business/GetUserDetailByBusiness?phoneNumber=${data.phoneNumber}&BranchId=${data.branchId}`
    }
    axios
      .get(query)
      .then(res => {
        console.log(res.data.userDetail);
        commit("setCustomer", res.data.userDetail)
      })
      .catch(error => {
        dispatch("errorGETHandler", error, { root: true });
      })
  },

  getVendorDetails({ dispatch, commit }) {
    axios
      .get("Business/GetBusinessForEdit")
      .then((res) => {
        console.log(res.data)
        commit("setVendorDetails", res.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  changeVendorLogo({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendor/logo", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorDetails({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    console.log(data)
    axios
      .put("Business/UpdateBusiness", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        console.log(error)
        dispatch("errorPOSTHandler", "updateBusiness", { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  // Lines Actions -----------------------------------------------------

  getVendoreLine({ dispatch, commit }, data) {
    axios
      .get("Business/GetLineForEdit?LineId=" + data)
      .then((res) => {
        console.log(res.data.lineDto)
        commit("setVendoreLine", res.data.lineDto);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  editVendoreLine({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("Business/UpdateLine", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  addVendoreLine({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("Business/CreateLine", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  // Branchs Actions ------------------------------------------------------------------
  getVendoreBranchs({ dispatch, commit }) {
    axios
      .get("Business/GetAllBranchesOfBusiness")
      .then((res) => {
        console.log(res.data.branches);
        commit("setVendoreBranchs", res.data.branches);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  getParts({ dispatch, commit }, data) {
    axios
      .get("Business/GetLinesForTransaction?BranchId=" + data)
      .then((res) => {
        console.log(res.data.lines)
        commit("setParts", res.data.lines);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getVendoreBranch({ dispatch, commit }, data) {
    axios
      .get("Business/GetBranchById?BranchId=" + data)
      .then((res) => {
        console.log(res.data.branch)
        commit("setVendoreBranch", res.data.branch);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendoreBranch({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/createBranch", data, {
        headers: { "Content-Type": "application/json" }
      }
      )
      .then((res) => {
        console.log(res.data);
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendoreBranch({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("Business/UpdateBranch", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranch({ dispatch }, data) {
    axios
      .delete("Business/DeleteBranch?BranchId=" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addVendoreBranchPart({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/part", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranchPart({ dispatch }, data) {
    axios
      .delete("Business/DeleteLine?LineId=" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandlerSimple",
          {
            server: res,
            message: alert.success.delete
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getVendoreBranchCustomers({ dispatch, commit }, data) {
    axios
      .get("Customer/GetCustomerByBusiness" + data)
      .then((res) => {
        commit("setVendoreBranchCustomers", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendoreBranchCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/customers", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendoreBranchCustomer({ dispatch }, data) {
    axios
      .delete("vendorBranch/customers?customerId=" + data.customer +
        "&branchPartId=" + data.part)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addVendoreBranchManager({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorBranch/user", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },


  // Vendor Levels Actions ------------------------------------------------------------------
  getVendorsLevel({ dispatch, commit }) {
    axios
      .get("vendorLevel")
      .then((res) => {
        commit("setVendorsLevel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendorLevel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorLevel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorLevel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendorLevel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendorLevel({ dispatch }, data) {
    axios
      .delete("vendorLevel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //Vendor Lebels Actions ------------------------------------------------------------------
  getVendorsLabel({ dispatch, commit }) {
    axios
      .get("vendorLabel")
      .then((res) => {
        commit("setVendorsLabel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addVendorLabel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("vendorLabel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editVendorLabel({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("vendorLebel/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteVendorLabel({ dispatch }, data) {
    axios
      .delete("vendorLabel/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //Customers Actions ------------------------------------------------------------------
  getCustomers({ dispatch, commit }) {
    axios
      // .get("Customer/getCustomerByBusiness?branchPartId=" + data)
      .get("Customer/getCustomerByBusiness?PageIndex=1&PageSize=50")
      .then((res) => {
        console.log(res.data.customers)
        commit("setCustomers", res.data.customers);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getCustomer({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("Customer/GetCustomerInfo?CustomerId=" + data)
      .then((res) => {
        console.log(res.data.customerDto)
        commit("setCustomer", res.data.customerDto);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    console.log(data)
    axios
      .post("Business/CreateCustomer", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data.id);
        if (res.data.id == '00000000-0000-0000-0000-000000000000') {
          dispatch(
            "successPOSTHandler",
            {
              server: res,
              message: "کارت مشتری قبلا در فروشگاه " + res.data.firstBusinessTitle + " ثبت شده است.",
              redirect: "CustomerList",
            },
            { root: true }
          );
        } else {
          commit("setCustomerCheck", res.data.id);
          dispatch(
            "successPOSTHandler",
            {
              server: res,
              message: alert.success.submit,
              redirect: "CustomerList",
            },
            { root: true }
          );
        }
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  assignCardToCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    console.log(data)
    console.log("بگو بینم اسمت چیه")
    axios
      .put("Business/AssignCardToCustomer", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data);
        commit("setCustomerCheck", true);
        if (window.localStorage.getItem("path") == "Transactions") {
          console.log(window.localStorage.getItem("path"))
          dispatch(
            "successPOSTHandler",
            {
              server: res,
              message: "کارت با موفقیت ایجاد شد",
              redirect: "Transactions",
            },
            { root: true }
          );
        } else {
          dispatch(
            "successPOSTHandler",
            {
              server: res,
              message: "کارت با موفقیت ایجاد شد",
            },
            { root: true }
          );
          commit("setCustomerCheck", null);
        }
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          window.localStorage.removeItem("path");
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  addCustomerToBusinessWithCard({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    console.log(data)
    axios
      .post("Business/AddCustomerToBusiness", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        console.log(res.data);
        commit("setCustomerCheck", null);
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: "مشتری با موفقیت اضافه شد",
            redirect: "CustomerList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  addCustomersWithExcel({ commit, dispatch }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customer/import", data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        dispatch('successPOSTHandler',
          {
            server: res,
            message: alert.success.addCustomers,
            redirect: false
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  exportCustomersExcel({ dispatch }) {
    axios
      .get("business/customer/export", {
        responseType: 'blob'
      })
      .then(res => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'customers.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      });
  },

  editCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/customer/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteCustomer({ dispatch }, data) {
    axios
      .delete("business/customer/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  checkCustomerMobile({ dispatch, commit }, data) {
    axios
      .get("business/customer/check?mobile=" + data.mobile +
        '&branchPartId=' + data.branchPart)
      .then((res) => {
        commit("setCustomerCheck", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Customer Level and Label Actions ------------------------------------------------------------------
  getCustomerLevel({ dispatch, commit }, data) {
    axios
      .get("business/customerLevel/" + data)
      .then((res) => {
        commit("setCustomerLevel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  updateCustomerLevel({ dispatch }, data) {
    axios
      .post("business/customerLevel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getCustomerLabel({ dispatch, commit }, data) {
    axios
      .get("business/customerLabel/" + data)
      .then((res) => {
        commit("setCustomerLabel", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  updateCustomerLabel({ dispatch }, data) {
    axios
      .post("business/customerLabel", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //Cards Actions ------------------------------------------------------------------
  getBankCards({ dispatch, commit }, data) {
    axios
      .get("business/customerCard/" + data)
      .then((res) => {
        commit("setCards", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addBankCard({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customerCard", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editBankCard({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/customerCard/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteBankCard({ dispatch }, data) {
    axios
      .delete("business/customerCard/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //Customer Transactions history Actions ------------------------------------------------------------------

  getCustomerTransactions({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .get("Business/GetAllCustomerTransactions?CustomerId=" + data)
      .then((res) => {
        console.log(res.data.transactions)
        commit("setCustomerHistoryTransactions", res.data.transactions);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  //SmartData Actions ------------------------------------------------------------------
  getSmartData({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("Setting/GetAllSmartData")
      .then((res) => {
        console.log(res.data.smartDataList)
        commit("setSmartData", res.data.smartDataList);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  getSmartDataById({ dispatch, commit }, data) {
    axios
      .get("Setting/GetSmartDataById?Key=" + data)
      .then((res) => {
        console.log(res.data.smartDataDto)
        commit("setSmartDataId", res.data.smartDataDto);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  getSmartDataMessages({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    let url
    if (data == null) {
      url = "SmartData/GetAllSmartDataRequests";
    } else {
      url = "SmartData/GetAllSmartDataRequests?SmartDataId=" + data;
    }
    axios
      .get(url)
      .then((res) => {
        console.log("Message : ", res.data.result)
        commit("setSmartDataMessage", res.data.result);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getCreditAndCashback({ dispatch, commit }) {
    axios
      .get("Discount/GetAllCreditEvent?Take=100&Skip=0")
      .then((res) => {
        console.log(res.data.results)
        commit("setCreditAndCashback", res.data.results);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  //Population Actions ------------------------------------------------------------------
  getPopulationCenters({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/population" + data)
      .then((res) => {
        commit("setPopulationCenters", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  getPopulationById({ dispatch, commit }, data) {
    axios
      .get("business/population/" + data)
      .then((res) => {
        commit("setPopulationId", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },
  //Message Request Actions ------------------------------------------------------------------
  getMessageRequest({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/messageRequest")
      .then((res) => {
        commit("setRequests", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  addRequest({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("SmartData/CreateSmartDataRequest", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: "SmartDataList",
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deleteRequest({ dispatch }, data) {
    axios
      .delete("business/messageRequest/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //PrizeShelf Actions ------------------------------------------------------------------
  getPrizeShelf({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/prizeShelf?branchPartId=" + data)
      .then((res) => {
        commit("setPrizeShelf", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  addPrizeShelf({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/prizeShelf", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  editPrizeShelf({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/prizeShelf/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deletePrizeShelf({ dispatch }, data) {
    axios
      .delete("business/prizeShelf/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  //VendorScore Actions ------------------------------------------------------------------
  getVendorScore({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/vendorScore?branchPartId=" + data)
      .then((res) => {
        commit("setVendorScore", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getVendorScoreDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/vendorScore/" + data)
      .then((res) => {
        commit("setVendorScoreDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addVendorScore({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/vendorScore", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  editVendorScore({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/vendorScore/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  deleteVendorScore({ dispatch }, data) {
    axios
      .delete("business/vendorScore/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  //ClubSharing Actions ------------------------------------------------------------------
  getSentRequest({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    const requestOne = axios.get("Business/GetAllShareBusiness?IsSends=true");
    const requestTwo = axios.get('Business/GetAllShareBranch?IsSends=true');

    Promise.all([requestOne, requestTwo])
      .then((res) => {
        console.log(res[0].data.result)
        console.log(res[1].data.dtos)
        commit("setSentBusinessRequest", res[0].data.result);
        commit("setSentBranchRequest", res[1].data.dtos);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getReceivedRequest({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    const requestOne = axios.get("Business/GetAllShareBusiness?IsSends=false");
    const requestTwo = axios.get('Business/GetAllShareBranch?IsSends=false');

    Promise.all([requestOne, requestTwo])
      .then((res) => {
        console.log(res[0].data.result)
        console.log(res[1].data.dtos)
        commit("setReceivedBusinessRequest", res[0].data.result);
        commit("setReceivedBranchRequest", res[1].data.dtos);
      })
      .then((res) => {
        commit("setReceivedRequest", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  ChangeBusinessStatusFiled({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put(`Business/CheckShareBusiness?ShareBusinessId=${data.shareBusinessId}&Accept=${data.value}`)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
  ChangeBranchStatusFiled({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put(`Business/CheckShareBranch?ShareBranchId=${data.shareBranchId}&IsAccept=${data.value}`)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  getReceivedReqId({ dispatch, commit }, data) {
    axios
      .get("business/clubSharing/" + data)
      .then((res) => {
        commit("setReceivedReqDetail", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addRequestClub({ dispatch, commit }, data) {
    let url;
    let query;
    if (data.requestFor == 1) {
      url = `Business/CreateShareBranch?`;
      query = `CreditEventId=${data.eventId}&TargetBranchId=${data.branchSelected}&NumberOfCustomers=${data.numberOfCustomer}&message=${data.message}`;
    } else if (data.requestFor == 2) {
      url = "Business/CreateShareBusiness?";
      query = `CreditEventId=${data.eventId}&TargetBusinessId=${data.vendorId}&NumberOfCustomers=${data.numberOfCustomer}&message=${data.message}`;

    }
    commit("setLoadingBtn", true, { root: true });
    axios
      .post(url + query)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  changeClubSharingStatus({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/clubSharing/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.changeStatus,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteClubSharing({ dispatch }, data) {
    axios
      .delete("business/clubSharing/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  getVendorList({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/clubSharing/vendors")
      .then((res) => {
        commit("setVendorsList", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  //Poll Actions ------------------------------------------------------------------
  getPolls({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("Poll/GetAllPoll")
      .then((res) => {
        console.log(res.data.polls)
        commit("setPolls", res.data.polls);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getPollDetails({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/poll/" + data)
      .then((res) => {
        commit("setPollDetails", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addPoll({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPoll({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePoll({ dispatch }, data) {
    axios
      .delete("business/poll/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addPollQuestion({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll/question", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPollQuestion({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/question/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePollQuestion({ dispatch }, data) {
    axios
      .delete("business/poll/question/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addPollOption({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/poll/option", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  editPollOption({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/poll/option/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deletePollOption({ dispatch }, data) {
    axios
      .delete("business/poll/option/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  // Transactions Actions ------------------------------------------------------------------

  getTransactionsWithExcel({ dispatch }) {
    console.log("This is excel.")
    axios
      .get("Business/GetTransactionAsExcel", {
        responseType: 'blob'
      })
      .then(res => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'transactions.xlsx');
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      });
  },


  getTransactions({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    console.log(data);
    axios
      // .get("Business/transaction?branchPartId=" + data)
      .get("Business/GetAllTransactions")
      .then((res) => {
        console.log(res.data.transactions)
        commit("setTransactions", res.data.transactions);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  getTransaction({ dispatch, commit }, data) {
    axios
      .get("business/transaction/" + data)
      .then((res) => {
        commit("setTransaction", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  addTransaction({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("Business/CreateTransaction", data, {
        headers: { "Content-Type": "application/json" },
      }).then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
        commit("setCustomer", {});
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  addTransactionCustomer({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .post("business/customer", data.customer)
      .then((res) => {
        data.transaction.customerId = res.data.data;
        dispatch("addTransaction", data.transaction);
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  addTransactionsWithExcel({ dispatch }, data) {
    axios
      .post("business/transaction/import", data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        dispatch('successPOSTHandler',
          {
            server: res,
            message: alert.success.addTransactions,
            redirect: false
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
  },

  addCustomersWithExcelInCredit({ dispatch }, data) {
    console.log("Data : ", data)
    axios
      .post("Discount/AddCustomerToCreditEvent", data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        console.log(res)
        dispatch('successPOSTHandler',
          {
            server: res,
            message: `شماره های ایجادی : ${res.data.addedNumbers}\nشماره های تکرای : ${res.data.duplicateNumbers}\nشماره های نامعتبر : ${res.data.invalidNumbers}`,
            redirect: false
          },
          { root: true })
      })
      .catch(error => {
        dispatch('errorPOSTHandler', error, { root: true });
      })
  },

  editTransaction({ dispatch, commit }, data) {
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("business/transaction/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },

  deleteTransaction({ dispatch }, data) {
    axios
      .delete("business/transaction/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },


  // Dashboard Action ------------------------------------------------------------------
  getDashboardData({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/dashboard?branchPartId=" + data)
      .then((res) => {
        commit("setDashboard", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  changeBusinessVisibility({ dispatch, commit }, data) {
    console.log(data)
    commit("setLoadingBtn", true, { root: true });
    axios
      .put("Business/UpdateVisibility", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoadingBtn", false, { root: true });
        }, 1000);
      });
  },
};
