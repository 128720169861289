import axios from "axios";
import alert from "@/lang/AlertMessages.js";
import label from "@/lang/StaticLabels.js";

export default {
  // All Discounts Actions ------------------------------------------------------------------
  getAllDiscounts({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount?branchPartId=" + data)
      .then((res) => {
        commit("setAllDiscounts", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },


  getDiscountCounts({ dispatch, commit }, data) {
    axios
      .get("business/discount/count?branchPartId=" + data)
      .then((res) => {
        commit("setDiscountCounts", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      });
  },

  // Discount Point Actions ------------------------------------------------------------------
  getPoint({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/point?branchPartId=" + data)
      .then((res) => {
        commit("setPoint", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addPoint({ dispatch }, data) {
    axios
      .post("business/discount/point", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editPoint({ dispatch }, data) {
    axios
      .put("business/discount/point/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Forget Actions ------------------------------------------------------------------
  getForget({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/forget?branchPartId=" + data)
      .then((res) => {
        commit("setForget", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addForget({ dispatch }, data) {
    axios
      .post("business/discount/forget", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editForget({ dispatch }, data) {
    axios
      .put("business/discount/forget/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount OffPlan Actions ------------------------------------------------------------------
  getOffPlan({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/off?branchPartId=" + data)
      .then((res) => {
        commit("setOffPlan", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addOffPlan({ dispatch }, data) {
    axios
      .post("business/discount/off", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editOffPlan({ dispatch }, data) {
    axios
      .put("business/discount/off/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Sale Actions ------------------------------------------------------------------
  getSale({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/sale?branchPartId=" + data)
      .then((res) => {
        commit("setSale", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addSale({ dispatch }, data) {
    axios
      .post("business/discount/sale", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editSale({ dispatch }, data) {
    axios
      .put("business/discount/sale/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Event Actions ------------------------------------------------------------------
  getEvent({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/event?branchPartId=" + data)
      .then((res) => {
        commit("setEvent", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addEvent({ dispatch }, data) {
    axios
      .post("business/discount/event", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editEvent({ dispatch }, data) {
    axios
      .put("business/discount/event/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Event Actions ------------------------------------------------------------------
  getCashback({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      // .get("business/discount/credit?branchPartId=" + data)
      .get("Discount/GetAllCashBack")
      .then((res) => {
        console.log(res.data.resultCashBackModels)
        commit("setCashback", res.data.resultCashBackModels);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },
  getCredit({ dispatch, commit }) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("Discount/GetAllCreditEventForBusiness?Take=100&Skip=0")
      .then((res) => {
        console.log(res.data.results)
        commit("setCredit", res.data.results);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addCashback({ dispatch }, data) {
    console.log("Submitted Date : ", data)
    axios
      .post("Discount/CreateCashBack", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  addCredit({ dispatch }, data) {
    console.log("Submitted Date : ", data)
    let query = `Dto.Title=${data.title}&Dto.Credit=${data.credit}&Dto.ExpireDate=${data.expireDate}&Dto.BranchId=${data.branchId}`
    axios
      .post(`Discount/CreateCreditEvent?${query}`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editCashback({ dispatch }, data) {
    axios
      .post("Discount/EditCashBack", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  editCredit({ dispatch }, data) {
    let query = `Dto.Key=${data.id}&Dto.Title=${data.title}&Dto.Credit=${data.credit}&Dto.ExpireDate=${data.expireDate}`

    axios
      .post("Discount/UpdateCreditEvent?" + query, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  deleteCredit({ dispatch }, data) {
    console.log("sa;dkljfalkjfl")
    axios
      .post("Discount/DeleteCreditEvent", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  deleteCashback({ dispatch }, data) {
    console.log("sa;dkljfalkjfl")
    axios
      .post("Discount/DeleteCashBack", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Loyalty Actions ------------------------------------------------------------------
  getLoyalty({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/loyalty?branchPartId=" + data)
      .then((res) => {
        commit("setLoyalty", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addLoyalty({ dispatch }, data) {
    axios
      .post("business/discount/loyalty", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editLoyalty({ dispatch }, data) {
    axios
      .put("business/discount/loyalty/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Discount Public Actions ------------------------------------------------------------------
  getPublic({ dispatch, commit }, data) {
    commit(
      "setLoading",
      { status: 1, message: label.caption.loading },
      { root: true }
    );
    axios
      .get("business/discount/public?branchPartId=" + data)
      .then((res) => {
        commit("setPublic", res.data.data);
      })
      .catch((error) => {
        dispatch("errorGETHandler", error, { root: true });
      })
      .finally(() => {
        setTimeout(() => {
          commit("setLoading", { status: 0, message: "" }, { root: true });
        }, 500);
      });
  },

  addPublic({ dispatch }, data) {
    axios
      .post("business/discount/public", data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.submit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },

  editPublic({ dispatch }, data) {
    axios
      .put("business/discount/public/" + data.id, data.value)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.edit,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
  // Delete Discount ........................................................................................
  deleteDiscount({ dispatch }, data) {
    axios
      .delete("business/discount/" + data)
      .then((res) => {
        dispatch(
          "successPOSTHandler",
          {
            server: res,
            message: alert.success.delete,
            redirect: false,
          },
          { root: true }
        );
      })
      .catch((error) => {
        dispatch("errorPOSTHandler", error, { root: true });
      });
  },
};
