import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./filters";
import '@babel/polyfill'
import "./plugins/toastification";
import "./plugins/persianDatePicker";

Vue.config.productionTip = false


// Vue.directive('format-number', {
//   bind(el) {
//     el.addEventListener('input', function (e) {
//       let value = e.target.value;
//       if (value) { // Check if value is not undefined or null
//         value = value.replace(/\D/g, '');
//         value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//         e.target.value = value;
//         // Trigger an input event to update the v-model
//         const event = new Event('input', { bubbles: true });
//         el.dispatchEvent(event);
//       }
//     });
//   }
// });


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
